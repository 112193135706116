import React, { useState } from "react";
import { Link } from "gatsby";
import SideNav from "../components/sideNav";

function HeaderHome() {
  const [isNavOpen, setNavOpen] = useState(false);

  return (
    <div className="flex items-center h-16 px-4 mx-auto bg-white shadow sm:bg-transparent sm:shadow-none xl:max-w-9/10">
      <nav className="relative flex items-center justify-between w-full sm:h-10 md:justify-end">
        <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link
              style={{ fontFamily: "Spinnaker", letterSpacing: ".25em" }}
              className="text-lg font-bold tracking-widest text-black uppercase sm:text-2xl lg:leading-8 lg:text-4xl "
              to="/"
              aria-label="Home"
            >
              CATALINA ADVISORS
            </Link>
            <div className="z-30 flex items-center -mr-2 md:hidden">
              <button
                type="button"
                onClick={() => setNavOpen(!isNavOpen)}
                id="sidebar-open"
                className={`${
                  isNavOpen ? "text-white" : "text-black"
                } inline-flex items-center p-2 justify-center lg:hidden focus:outline-none`}
                //className="inline-flex items-center justify-center p-2 text-gray-900 lg:hidden focus:outline-none focus:text-white"
                aria-label="Open site navigation"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <SideNav isOpen={isNavOpen} />

        <div className="hidden md:flex md:space-x-10">
          <Link
            to="/services"
            className="text-lg text-gray-900 border-b-2 border-transparent hover:border-green-500 hover:text-green-600"
          >
            Services
          </Link>
          {/* <Link
            to="/blog"
            className="text-lg text-gray-900 border-b-2 border-transparent hover:border-green-500 hover:text-green-600"
          >
            Blog
          </Link> */}
          <Link
            to="/contact"
            className="text-lg text-gray-900 border-b-2 border-transparent hover:border-green-500 hover:text-green-600"
          >
            Contact
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default HeaderHome;
