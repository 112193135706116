import React from "react";

import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import Div100vh from "react-div-100vh";



import strategies from "../images/service-strategies.png";



import HeaderHome from "../components/header-home";
import Footer from "../components/footer";

function IndexPage() {
  const data = useStaticQuery(graphql`
    query PlaneQuery {
      plane: file(absolutePath: { regex: "/background-plane.png/" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
        description="Experienced corporate finance advisors."
      />
      <Div100vh className="relative overflow-hidden bg-gray-50">
        <Img
          style={{
            position: "absolute",
          }}
          fluid={data.plane.childImageSharp.fluid}
          className="z-20 xl-pos-plane xl:block"
        />
        <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
          <div className="relative h-full max-w-screen-xl mx-auto">
            <svg
              className="absolute transform right-full translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute transform top-right-rec left-full -translate-x-1/4 md:-translate-y-1/10 lg:-translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
              />
            </svg>
          </div>
        </div>

        <div className="relative z-30 h-full pt-0 sm:pt-10">
          <HeaderHome />

          <main className="flex flex-col h-full max-w-screen-xl px-4 pt-20 pb-48 my-auto xl:mx-auto xl:mt-0 sm:justify-center ">
            <div style={{ maxWidth: 705 }}>
              <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Experienced Corporate
                <br className="" />
                <span className="text-green-600">Finance Consultants</span>
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Catalina provides corporate finance solutions for small and
                middle-market businesses across a diverse set of industries.
                Contact us today to schedule a no-cost introductory meeting.
              </p>

              <div className="flex justify-start mt-5 space-x-4 md:mt-8">
                <Link
                  to="/services"
                  className="flex items-center justify-center max-w-sm px-6 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md shadow hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green md:py-4 md:text-lg md:px-10"
                >
                  Our Services
                </Link>

                <Link
                  to="/contact"
                  className="flex items-center justify-center max-w-sm px-6 py-3 text-base font-medium leading-6 text-green-600 transition duration-150 ease-in-out bg-white border border-transparent rounded-md shadow sm:mt-0 sm:ml-3 hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green md:py-4 md:text-lg md:px-10"
                >
                  Contact Us
                </Link>
              </div>
            </div>
            <div
              style={{ left: "35%" }}
              className="absolute bottom-0 hidden pb-10 lg:block "
            >
              <dl className="mt-6 text-base leading-6 text-gray-500">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <svg
                    className="flex-shrink-0 w-8 h-8 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <span className="flex items-center ml-3 text-xl font-bold text-gray-900">
                    (346) 217-5882
                  </span>
                </dd>
              </dl>
            </div>
          </main>
        </div>
      </Div100vh>
      <section className="px-4 py-16 mx-auto overflow-hidden md:py-24  max-w-7xl">
        <div className="relative">
          <div className="absolute top-0 bottom-0 hidden w-screen lg:block bg-gray-50 left-3/4"></div>
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <p className="text-base font-semibold leading-6 tracking-wide text-green-600 uppercase">
              Commercial Finance Solutions
            </p>
            <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Extensive Relationships.
            </h1>
          </div>

          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
              <svg
                className="absolute top-0 right-0 hidden -mt-20 -mr-20 lg:block"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative mx-auto text-base max-w-prose lg:max-w-none">
                <figure>
                  <div className="relative pb-7/12 lg:pb-0">
                    <img
                      src={strategies}
                      alt="service-strategies"
                      width="1184"
                      height="1376"
                      className="absolute inset-0 object-cover object-center w-full h-full rounded-lg shadow-lg lg:static lg:h-auto"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div>
              <div className="mx-auto text-base max-w-prose lg:max-w-none">
                <p className="mb-5 text-lg leading-7 text-gray-500">
                All growing businesses need capital. At times, it can be difficult for a management team to know where to turn to finance an expansion, acquisition, or even working capital growth. Catalina can help. 
                </p>
              </div>
              <div className="mx-auto mb-8 prose text-gray-500 lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p>
                We have extensive relationships with capital providers of all forms and can help your business partner with the right group for its needs. Our goal is to alleviate the complexity surrounding the financial demands of your business. We ensure your business gets the best capital structure at the lowest cost every time.
                </p>
              </div>

              <div className="mx-auto mb-8 text-base max-w-prose lg:max-w-none">
                <p className="text-base font-semibold leading-6 tracking-wide text-green-600 uppercase">
              Corporate Finance Consulting
                </p>
                <h1 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Deep Expertise.
                </h1>
              </div>
              <div className="mx-auto mb-8 prose text-gray-500 lg:max-w-none lg:row-start-1 lg:col-start-1">
                <div className="mx-auto text-base max-w-prose lg:max-w-none">
                  <p className="mb-5 text-lg leading-7 text-gray-500">
                  Management teams get pulled in many different directions. It can be challenging to prioritize your day-to-day finance requirements over sales, operations, and other departments. Often, management teams simply do not have the bandwidth to tackle all the priorities of the business. That’s where we come in. 
                  </p>
                </div>

                <p>
                  Whether you need the full expertise of a part-time CFO or just some help with your bookkeeping, Catalina can augment your management team so that you can focus on more pressing parts of your business.
                </p>
              </div>
              <div className="mx-auto text-base max-w-prose lg:max-w-none">
                <p className="text-base font-semibold leading-6 tracking-wide text-green-600 uppercase">
                 Bank Loan Restructuring
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Proven Strategies.
                </h1>
              </div>
              <div className="mx-auto prose text-gray-500 lg:max-w-none lg:row-start-1 lg:col-start-1">
                <div className="mx-auto text-base max-w-prose lg:max-w-none">
                  <p className="mb-5 text-lg leading-7 text-gray-500">
                  When a business experiences challenging market conditions, it can be difficult for management to work with existing lenders. Not only must a management team continue to operate the business in a tough environment, but it must also be concerned about its bank! Catalina can help.
                  </p>
                </div>

                <p>
                We are owned and operated by former bankers and are experienced in working through challenging loan situations. Whether you simply need another voice in the room or need Catalina to take the lead on a full restructuring process, we help companies navigate the difficulties associated with tough loan situations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="bg-green-50">
        <div className="max-w-2xl px-4 py-16 mx-auto text-center md:py-24 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold leading-9 sm:text-4xl sm:leading-10">
            <span className="block text-gray-900 ">Want to Learn More?</span>
            <span className="block text-green-600">
              Schedule an Introductory Meeting.
            </span>
          </h2>
          <Link
            to="/contact"
            className="inline-flex items-center justify-center w-full px-5 py-3 mt-8 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500 sm:w-auto"
          >
            Schedule Now
          </Link>
        </div>
      </div> */}

      <div className="bg-green-50">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 md:text-4xl md:leading-10">
            <span className="block">Ready to learn more?</span>
            <span className="block text-green-600">
              Schedule an introductory meeting.
            </span>
          </h2>
          <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="h-16 bg-white md:h-24" />
      <Footer/>
    </>
  );
}

export default IndexPage;
